import Chart from 'chart.js';

const Charts = {
  init(){
    $('canvas[data-type="line-chart"]').each((index, element) => {
      let lineChartData = JSON.parse(element.dataset.val);
      SK.Charts.lineChart(element, lineChartData);
    });

    $('canvas[data-type="doughnut-chart"]').each((index, element) => {
      let chartData = JSON.parse(element.dataset.val);
      SK.Charts.doughnutChart(element.id, chartData);
    });

    if($('.area-chart-link').length){
      $('.area-chart-link').first().trigger('click');
    }

    if($('.chart-dropdown').length){
      $('.chart-dropdown .dropdown-item').first().trigger('click');
    }

    if($('.barchart-dropdown .dropdown-item').length){
      $('.barchart-dropdown .dropdown-item').first().trigger('click');
    }

    if($('#doughnut-chart').length){
      let chartUrl = $('#doughnut-chart').data('chart-url');
      SK.Charts.show(chartUrl);
    }
  },

  // charts are made using chart js
  lineChart(element, json_data){
    Chart.defaults.global.legend.display = false;
    const ctx = element.getContext('2d');

    const labels = Object.keys(json_data);
    const values = Object.values(json_data);

    new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          label: false,
          data: values,
          borderColor: 'rgba(54, 162, 235, 0.9)',
          borderWidth: 2,
          fill: false
        }]
      },
      options: {
        elements: {
          point:{
            radius: 0
          }
        },
        scales: {
          xAxes: [{
            ticks: {
              display: false
            },
            gridLines: {
              display: false,
              drawBorder: false
            }
          }],
          yAxes: [{
            ticks: {
              display: false,
            },
            gridLines: {
              display: false,
              drawBorder: false
            }
          }]
        }
      }
    });
  },

  customBarChart(element_id, json_data, options){
    const element = $(`#${element_id}`);
    element.empty();
    let y_values;

    if(json_data.keys){
      y_values = json_data.values;
    } else {
      y_values = Object.values(json_data);
    }

    const container_height = element.parent().height() - 20; // 20 px height of the bottom label
    const height_fraction = container_height / Math.max.apply(null, y_values);
    const bar_width = 100 / y_values.length;

    if(json_data.keys) {
      $.each(json_data.keys, (i, key) => {
        let y_axis_val = json_data.values[i];
        let x_axis_label = json_data.x_axis_labels[i];
        let height = height_fraction * y_axis_val;

        let bar = `<div class='bar' style=width:${bar_width}%;>
          <span class='tick' style='height:${height}px'></span>
          <span class='label'>${key}<em>${x_axis_label}</em></span>
        </div>`

        element.append(bar);
      });
    } else {
      $.each(json_data, (x_axis_val, y_axis_val) => {
        const height = height_fraction * y_axis_val;
        let bar = `<div class='bar' style=width:${bar_width}%;>
          <span class='tick' style='height:${height}px;'></span>
          <span class='label'>${x_axis_val}<em>${y_axis_val}</em></span>
        </div>`

        element.append(bar);
      });
    }
  },

  areaChart(element_id, json_data, label){
    const height = window.innerHeight - ($('.navbar-dashboard').outerHeight() +
                                       $('.sk-main-container_header').outerHeight() +
                                       parseInt($('.sk-main-container_header').css('margin-bottom')) +
                                       $('.sk-dashboard-card-container').outerHeight() +
                                       parseInt($('.sk-dashboard-info-container').css('margin-top'))
                                      );
    $('.sk-dashboard-info-container').css('height', `${height}px`);

    Chart.defaults.global.legend.display = false;

    const ctx = document.getElementById(element_id).getContext('2d');
    const labels = Object.keys(json_data);
    const values = Object.values(json_data);

    new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          label: label,
          data: values,
          backgroundColor: [
            'rgba(0,173,237, 0.2)'
          ]
        }]
      },
      options: {
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0, // disables bezier curves
          }
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              var val = data['datasets'][0]['data'][tooltipItem['index']] + '';
              val = val.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
              return label + ': ' + val;
            },
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: false
            }
          }]
        }
      }
    });
  },

  doughnutChart(element_id, json_data){
    Chart.defaults.global.legend.display = true;
    var ctx = document.getElementById(element_id).getContext('2d');
    var labels = Object.keys(json_data);
    var values_and_colors = Object.values(json_data);
    var values = values_and_colors.map(function(a) { return a[0]});
    var bg_colors = values_and_colors.map(function(a) { return a[1]});
    
    new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: [{
          label: 'Types',
          data: values,
          backgroundColor: bg_colors
        }]
      },
      options: {
        cutoutPercentage: 70,
        responsive: true,
        legend: {
          position: 'right',
        },
        title: {
          display: false,
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        tooltips: {
          callbacks: {
            title: (tooltipItem, data) => {
              return data['labels'][tooltipItem[0]['index']];
            },
            label: (tooltipItem, data) => {
              return data['datasets'][0]['data'][tooltipItem['index']] + '%';
            }
          }
        }
      }
    });
  },

  show(url){
    if(url !== undefined && url !== '#'){
      $.get(url, null, null, 'script');
    }
  }
}

export default Charts;
