const trackingScripts = {
  execChameleon(){
    !function(t,n,o){var a="chmln",e="adminPreview",c="setup identify alias track clear set show on off custom help _data".split(" ");if(n[a]||(n[a]={}),n[a][e]&&(n[a][e]=!1),!n[a].root){n[a].accountToken=o,n[a].location=n.location.href.toString(),n[a].now=new Date;for(var s=0;s<c.length;s++)!function(){var t=n[a][c[s]+"_a"]=[];n[a][c[s]]=function(){t.push(arguments)}}();var i=t.createElement("script");i.src="https://fast.trychameleon.com/messo/"+o+"/messo.min.js",i.async=!0,t.head.appendChild(i)}}(document,window,"S6hdfCHrejkSNRbP4pq2iaQPgnc4eJuTgglhnuOQedtfK6-1HlcAi-BlhCHNczBRtGlRQH");
    chmln.identify(userInfo.id, userInfo);
  },
  
  execGoogleAnalytics(){
    // https://github.com/turbolinks/turbolinks/issues/73#issuecomment-812484452
    if(typeof(gtag) == "function"){
      gtag('config', 'UA-85695292-5', {
        "page_title": document.title,
        "page_path": location.href.replace(location.origin, "")
      });
    }
  },

  execAll(){
    SK.trackingScripts.execChameleon();
    SK.trackingScripts.execGoogleAnalytics();
  }
}

export default trackingScripts;
