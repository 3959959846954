const Profile = {
  handleScroll(){
    const scroll_content = $('#alacarte-packages .property-cards');
    SK.Profile.scroll_width = SK.Profile.scroll_width || $('.alacarte-packages-content')[0].scrollWidth;
    SK.Profile.window_width = SK.Profile.window_width || $('.alacarte-packages-content')[0].offsetWidth;
    SK.Profile.initial_margin = SK.Profile.initial_margin || Math.abs(parseInt(scroll_content.css('margin-left')));
    SK.Profile.shifts = SK.Profile.shifts || [];

    let card_width = $('#alacarte-packages .card').first().width() * 3; // 2 cards at a time
    let shift_size = SK.Profile.scroll_width - (SK.Profile.window_width + Math.abs(parseInt(scroll_content.css('margin-left'))));

    if(!SK.Profile.scroll_reverse){
      let margin_left = (Math.min(card_width, shift_size) + SK.Profile.initial_margin);
      SK.Profile.shifts.push(margin_left);

      scroll_content.animate({
        marginLeft: ('-=' + margin_left + 'px')
      }, 500, () => {
        shift_size = SK.Profile.scroll_width - (SK.Profile.window_width + Math.abs(parseInt(scroll_content.css('margin-left'))));

        if (shift_size <= 0){
          SK.Profile.scroll_reverse = true;
          SK.Profile.shifts = SK.Profile.shifts.reverse();
          $('.alacarte-packages-scroll-handler').addClass('prev');
        }
      });
    } else {
      scroll_content.animate({
        marginLeft: ('+=' + SK.Profile.shifts.pop() + 'px')
      }, 500, () => {
        if (SK.Profile.shifts.length == 0){
          SK.Profile.scroll_reverse = false;
          $('.alacarte-packages-scroll-handler').removeClass('prev');
        }
      });
    }
  }
}

export default Profile;
