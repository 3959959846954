import Rails from "@rails/ujs"
import { event } from "jquery";

// handle left and right arrow click
$(document).on('click', '.sk-v2_left-panel li', function(){
  if (!$('.sk-v2_left-panel').hasClass('active')){
    $('.sk-v2_left-panel').addClass('active');
  }
});

$(document).on('change', '.properties-managed_list input', function(){
  let $this = $(this);

  if($(this).attr('id') == 'property_emails_toggle_all'){
    $('.properties-managed_list tbody input').prop('checked', $this.is(':checked'));
  } else {
    if(!$this.is(':checked')){
      // if any tbody input unchecked checked, uncheck master
      $('#property_emails_toggle_all').prop('checked', false);
    } else {
      // if all tbody input checked, check master
      if($('.properties-managed_list tbody input').length == $('.properties-managed_list tbody input:checked').length){
        $('#property_emails_toggle_all').prop('checked', true);
      }
    }
  }

  Rails.fire($(this).parents('form')[0], 'submit');
});

// charts
$(document).on('click', '.chart-dropdown .dropdown-item, .barchart-dropdown .dropdown-item', (event) => {
  event.preventDefault();
  $(event.currentTarget).parents('.dropdown').find('.dropdown-toggle').text(event.currentTarget.text);
  SK.Charts.show(event.currentTarget.getAttribute('href'));
});

$(document).on('click', '.area-chart-link', (event) => {
  event.preventDefault();
  const link = $(event.currentTarget);
  link.parents('.dropdown').find('.chart-dropdown-handler').text(link.text());
  const cloned_element = $('#area-chart').clone();
  $('#area-chart').replaceWith(cloned_element);
  SK.Charts.areaChart('area-chart', link.data('val'), link.data('label') || link.text());
});

$(document).on('click', '.post-dropdown .dropdown-item', (event) => {
  event.preventDefault();
  $('.post-dropdown-handler').text(event.currentTarget.text);

  if($(event.currentTarget).hasClass('latest')){
    $('ul.latest-posts').removeClass('forced-hidden');
    $('ul.top-posts').addClass('forced-hidden');
  } else {
    $('ul.latest-posts').addClass('forced-hidden');
    $('ul.top-posts').removeClass('forced-hidden');
  }
});

$(document).on('click', '.keyword-dropdown .dropdown-item', (event) => {
  event.preventDefault();
  $('.keyword-dropdown-handler').text(event.currentTarget.text);

  if(event.currentTarget.dataset.target == 'top-google-keywords'){
    $('#keywords-csv-link, .top-google-keywords').removeClass('forced-hidden');
    $('#ads-csv-link, .top-google-ads').addClass('forced-hidden');
  } else {
    $('#keywords-csv-link, .top-google-keywords').addClass('forced-hidden');
    $('#ads-csv-link, .top-google-ads').removeClass('forced-hidden');
  }
});

$(document).on('click', '.ads-dropdown .dropdown-item', (event) => {
  event.preventDefault();
  $('.ads-dropdown-handler').text(event.currentTarget.text);
  $('.top-facebook-ads, .top-instagram-ads, .top-google-ads').addClass('forced-hidden');
  $(`.${$(event.currentTarget).data('target')}`).removeClass('forced-hidden');
});

// modals
$(document).on('click', 'a[data-remote-modal]', (event) => {
  event.preventDefault();
  let link = event.currentTarget;
  SK.Modal.showRemoteModal(link.href, link.dataset.title, link.dataset.modalClass, link.dataset.modalWidth);
});

$(document).on('click', 'a[data-modal]', (event) => {
  event.preventDefault();
  SK.Modal.showModal(event.currentTarget.title, $(`.${event.currentTarget.dataset.modalClass}`).html());
});

// profiles
$(document).on('click', '.alacarte-packages-scroll-handler', () => {
  SK.Profile.handleScroll();
});

// properties
$(document).on('change', '#sk-left-panel_property-select', (event) => {
  window.location.href = $(event.currentTarget).find('option:selected').data('url');
});

$(document).on('click', '.property-cards .btn-light', (event) => {
  event.preventDefault();
  SK.Modal.showModal('Submissions Closed', 'Submission is closed for the time being. Please wait until this is enabled again.');
});

$(document).on('change', '#ad_set_id', (event) => {
  var url = $(event.currentTarget).data('base-url').replace('ad_set_id', $(event.currentTarget).val());
  $.get(url, (html_data) => {
    $('#campaign-detail').html(html_data);
  });
});

$(document).on('click', '.asset-auditing_list_item_info', (event)=> {
  $(event.currentTarget).parents('li').toggleClass('detail-visible');
});

$(document).on('change', '.custom-file-filed_wrapper input', (event)=> {
  let fileName = event.currentTarget.value.replace(/^.*[\\\/]/, '');
  $(event.currentTarget).parent().find('.file-name').text(fileName);
});

$(document).on('turbolinks:load', () => {
  SK.Utility.showCardProgress();
  SK.Utility.highlightNav();
  SK.Utility.checkBrokenPostImages();
  SK.Utility.showFlashError();
  SK.Utility.showFlashNotice();
  SK.Utility.setImageHeights();
  SK.Utility.initSelect2Fields();
  SK.Utility.initAllDateRangePicker();
  SK.Charts.init();
  SK.Reports.initIndex();

  $('.scrollbar-dynamic, .scrollbar-outer, .scrollbar-inner').scrollbar();

  // accept tos popup
  const acceptTosPopup = $('#accept-tos-popup');
  if (acceptTosPopup.length){
    SK.Dashboard.showStaticModal('accept-tos-popup', 'accept-tos-popup modal-dialog-centered');
  }

  // properties live sync
  if($(document.body).data('live-sync')){
    const propertyId = $(document.body).data('property-id');
    SK.Utility.showLoading({ property_id: propertyId });
  } else {
    SK.Utility.hideLoading({ forced: true });
  }

  // error popup
  const errorPopupContent = $('#error-popup-content');
  if(errorPopupContent.length){
    SK.Utility.showFlashError(errorPopupContent.text());
  }

  if(typeof(productionEnv) !== 'undefined'){
    SK.trackingScripts.execAll();
  }
});
