// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.

const FormSubmissions = {
  requestPackageMessage(response_type, message){
    const el = $('#recommended-package .message-container');

    el.removeClass('hidden').addClass(response_type).html(message);

    setTimeout(() => {
      el.html('').removeClass().addClass('message-container hidden');
    }, 5000);
  }
}

export default FormSubmissions;
