const Dashboard = {
  showStaticModal(element_id, modal_class){
    SK.Modal.close();

    const element = document.getElementById(element_id);
    const content = element.innerHTML;
    element.remove();

    if(modal_class){
      modal_class = `welcome-modal ${modal_class}`;
    } else {
      modal_class = 'welcome-modal';
    }

    SK.Modal.showModal('', content, modal_class);
  }
};

export default Dashboard;
