const Reports = {
  initIndex(){
    const reportsTable = $('#reports-tbody');
    if (reportsTable.length){
      SK.Reports.initRefresh(reportsTable.data('focus'));
    }
  },

  resetForm(){
    $('#report-properties-select, #report_type').val(null).trigger('change');
  },

  initRefresh(reportId){
    if ($('.scheduled').length){
      clearTimeout(SK.Reports.timer);
      SK.Reports.timer = setTimeout(() => {
        let report_ids = $('.scheduled').map((i, el) => { return $(el).data('id')}).get().join(',')
        let url = $('#reports-tbody').data('scheduled-status-url') + `?ids=${report_ids}`;
        $.get(url, null, null, 'script');
      }, 5000);
    }

    if(reportId){
      $('html, body').animate({
        scrollTop: $(`#report-${reportId}`).offset().top
      }, 500);
    }
  }
}

export default Reports;
