// Common javascript to entire site should come here
// This is highly encouraged to move page specific javascript to specific files

import moment from 'moment';

const Utility = {
  // http://www.daterangepicker.com
  initDateRangePicker(startDate, endDate) {
    const yesterday = moment().subtract(1, 'days');
    const start = moment().subtract(timeframe, 'days');

    // callback of apply
    function dateSelectCB(start, end) {
      SK.Utility.showLoading();
      let baseUrl = $('#reportrange').data('base-url');
      window.location.href = `${baseUrl}?from=${start.format('YYYYMMDD')}&to=${end.format('YYYYMMDD')}`;
    }

    const options = {
      startDate: startDate,
      endDate:   endDate,
      yesterday: yesterday,
      callback:  dateSelectCB,
      minDate:   start
    }

    SK.Utility.initDateRangeHandler('#reportrange', options);
  },

  initReportDateRangerPicker(startDate, endDate) {
    const yesterday = moment().subtract(1, 'days');
    const start     = moment().subtract(timeframe, 'days');
    const minDate   = start;

    // callback of apply
    function dateSelectCB(start, end) {
      $('#date_start').val(start.format('YYYY-MM-DD'));
      $('#selectFrom').val(start.format('MM/DD/YY'));

      $('#date_end').val(end.format('YYYY-MM-DD'));
      $('#selectTo').val(end.format('MM/DD/YY'));
    }

    var options = {
      startDate: startDate,
      endDate:   endDate,
      yesterday: yesterday,
      callback:  dateSelectCB,
      minDate:   minDate
    }

    SK.Utility.initDateRangeHandler('#reportrange', options);
  },

  initDateRangeHandler(element_id, options){
    $(element_id).daterangepicker({
      startDate: options.startDate,
      endDate:   options.endDate,
      maxDate:   options.yesterday,
      minDate:   options.minDate,
      opens:     'left',
      autoApply: false,
      autoUpdateInput: false,
      alwaysShowCalendars: true,
      maxSpan: {
        days: timeframe
      },
      ranges: {
        'Yesterday':    [options.yesterday, options.yesterday],
        'Last 7 Days':  [moment().subtract(7, 'days'), options.yesterday],
        'Last 30 Days': [moment().subtract(30, 'days'), options.yesterday],
        'This Month':   [moment().startOf('month'), options.yesterday],
        'Last Month':   [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, options.callback);

    // check if text of the selector text is being modified
    $('.drp-selected').on('DOMSubtreeModified',() => {
      if($('.drp-selected').text().length && !$('.drp-selected span').length){
        let dates     = $('.drp-selected').text().split('-');
        let startDate = moment(dates[0], 'MM/DD/YYYY');
        let endDate   = moment(dates[1], 'MM/DD/YYYY');
        let diffDays  = endDate.diff(startDate, 'days') + 1;
        diffDays      = diffDays + (diffDays > 1 ? ' days' : ' day');

        $('.drp-selected').html(`Selected: <span>${startDate.format('MM/DD/YY')}</span> to <span>${endDate.format('MM/DD/YY')}</span> <em>(${diffDays})</em>`);
      }
    });
  },

  setChartHeight(){
    var height = window.innerHeight - ($('.navbar-dashboard').height() + $('.sk-main-container_header').height() + $('.sk-dashboard-card-container').height());
    $('.sk-dashboard-info-container').css('height', (height - 50) + 'px');
  },

  showLoading(options){
    if (options && options.property_id){
      localStorage.setItem('liveSync', options.property_id);
    }

    SK.Utility.loadingTimeout = setTimeout(() => {
      $('.loading-wrapper').show();
    }, 500);
  },

  hideLoading(options){
    if (options && options.forced){
     localStorage.removeItem('liveSync');;
    }

    if(!localStorage.getItem('liveSync')) {
      clearTimeout(SK.Utility.loadingTimeout);
      $('.loading-wrapper').hide();
    }
  },

  showFlashError(msg){
    var el_id = '.flash.alert-danger';

    if(msg){
      if(!$(el_id).length){
        $(document.body).append('<p class="flash alert alert-danger"></p>');
      }

      $(el_id).html(msg);
    }

    if($(el_id).length){
      setTimeout(() => {
        $(el_id).slideDown();
      }, 200);

      setTimeout(() => {
        $(el_id).slideUp();
      }, 10000);
    }
  },

  showFlashNotice(msg){
    var el_id = '.flash.alert-success';

    if(msg){
      if(!$(el_id).length){
        $(document.body).append('<p class="flash alert alert-success"></p>');
      }

      $(el_id).html(msg);
    }

    if($(el_id).length){
      setTimeout(() => {
        $(el_id).slideDown();
      }, 200);

      setTimeout(() => {
        $(el_id).slideUp();
      }, 5000);
    }
  },

  progressBar(element, percentage){
    let bar1 = new ldBar(`#${element}`);
    let bar2 = document.getElementById(element).ldBar;
    bar1.set(percentage);
  },

  highlightNav(){
    let current_path = window.location.pathname;
    $('.sk-v2_left-panel li').removeClass('active');

    $('.sk-v2_left-panel a').each((i, link) => {
      if($(link).attr('href').split('?')[0] == current_path){
        $(link).parents('li').addClass('active');
      }
    });
  },

  setImageHeights(){
    let el = $(`.set-image-height`);

    if(el.data('height')){
      setTimeout(() => {
        el.find('img').css({ height: el.first().height() + 'px'});
        el.find('img').fadeIn();
      }, 1000);
    } else {
      el.find('img').css({ height: el.first().width() + 'px'});
    }
  },

  showCardProgress(){
    let $ppc = $('.progress-pie-chart'),
      percent = parseInt($ppc.data('percent')),
      deg = 360*percent/100;

    if (percent > 50) {
      $ppc.addClass('gt-50');
    }

    $('.ppc-progress-fill').css('transform',`rotate(${deg}deg)`);
    $('.ppc-percents span').html(`${percent}%`);
  },

  // Some of the instagram or instagram post images are often broken due to expired signature
  // in case of image error, refetch them
  checkBrokenPostImages(){
    $('.check-broken').on('error', (event) => {
      let img = $(event.currentTarget);
      let id  = img.data('id');
      let post_type = img.data('post-type');

      $.get('/refetch_media/' + id + '?media_type=' + post_type, function(data){
        if (data.url == 'post_not_present'){
          window.location.reload();
        } else {
          let parent = img.parent();
          if(parent[0].tagName == 'VIDEO'){
            img.remove();
            parent.append(`<source src="${data.url}" type="video/mp4" />`);
          } else {
            img.attr('src', data.url).removeClass('check-broken');
          }
        }
      });
    });
  },

  // this is for ajax/pjax load
  // can be called from page itself, or pjax complete
  initiTypeformButtons(){
    $('.typeform-share').each((index, el) => {
      let url = $(el).attr('href');
      let popup = window.typeformEmbed.makePopup(url, {
        hideHeaders: true,
        hideFooter: true
      });

      $(el).on('click', (e) => {
        e.preventDefault();
        popup.open();
      });
    });
  },

  initSelect2Fields(){
    $('.select2-select').each((index, el) => {
      let select2Field = $(el);
      let placeholder = select2Field.data('placeholder');
      
      if(placeholder) {
        select2Field.select2({placeholder: placeholder});
      } else {
        select2Field.select2();
      }
    });
  },

  initAllDateRangePicker(){
    $('.date-range-picker-container').each((index, el) => {
      let container = $(el);
      let selectFrom = $('#selectFrom').val();
      let selectTo = $('#selectTo').val();
      if(container.data('report')){
        SK.Utility.initReportDateRangerPicker(selectFrom, selectTo);
      } else {
        SK.Utility.initDateRangePicker(selectFrom, selectTo);
      }
    });
  },

  handleFormErrors(formCLass, fieldPrefix, errors){
    for (const [key, messages] of Object.entries(errors)) {
      let formGroup = $(`#${fieldPrefix}_${key}`).parents('.form-group');
      formGroup.find('.text-danger').remove();
      formGroup.addClass('field-with-error').append(`<span class="text-danger">${messages}</span>`);
    }
  }
}

export default Utility;
